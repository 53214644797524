import classNames from 'classnames';
import classes from 'components/properties/attributes/PropertyOccupancyStatus/index.module.scss';
import { OccupancyStatuses } from 'consts/enums/property';
import countBy from 'lodash/countBy';
import EvictionIcon from 'public/assets/icons/eviction.svg';
import LeaseExpiredIcon from 'public/assets/icons/lease_expired.svg';
import OccupiedIcon from 'public/assets/icons/occupied.svg';
import RenovationIcon from 'public/assets/icons/renovation.svg';
import SignRentIcon from 'public/assets/icons/sign_rent.svg';
import { ReactElement } from 'react';
import { PropertyType } from 'types/property-types';

export const enum ActualOccupancyStatus {
  LEASED,
  EVICTION,
  FOR_RENT,
  EXPIRED,
  UNDER_RENOVATION,
}

const OccupancyToActualStatus = {
  [OccupancyStatuses.LEASED]: ActualOccupancyStatus.LEASED,
  [OccupancyStatuses.SIGNED]: ActualOccupancyStatus.LEASED,
  [OccupancyStatuses.SIGNED_LEASE]: ActualOccupancyStatus.LEASED,
  [OccupancyStatuses.MARKETING]: ActualOccupancyStatus.FOR_RENT,
  [OccupancyStatuses.MARKETING_AND_WAITING_FOR_PHOTOS]:
    ActualOccupancyStatus.FOR_RENT,
  [OccupancyStatuses.UNDER_ACQUISITION_REHAB]:
    ActualOccupancyStatus.UNDER_RENOVATION,
  [OccupancyStatuses.UNDER_TURNS_REHAB]: ActualOccupancyStatus.UNDER_RENOVATION,
  [OccupancyStatuses.EVICTION]: ActualOccupancyStatus.EVICTION,
  [OccupancyStatuses.HOLD_OVER]: ActualOccupancyStatus.EXPIRED,
};

type OccupancyLabelItemType = {
  display:
    | string
    | ((props: { vacantUnitsCount: number; unitsCount: number }) => string);
  Icon?: ReactElement;
  containerClassName?: string;
};

type OccupancyLabelsMapType = {
  [key in ActualOccupancyStatus]: OccupancyLabelItemType;
};

export const OccupancyStatusMap: OccupancyLabelsMapType = {
  [ActualOccupancyStatus.LEASED]: {
    display: ({ vacantUnitsCount, unitsCount }) => {
      const occupied = unitsCount - vacantUnitsCount;
      const prefix = unitsCount > 1 ? `${occupied}/${unitsCount} ` : '';
      return prefix + `Leased`;
    },
    Icon: (
      <OccupiedIcon
        className={classNames(classes.icon, classes.occupiedIcon)}
      />
    ),
  },
  [ActualOccupancyStatus.FOR_RENT]: {
    display: 'For rent',
    Icon: <SignRentIcon className={classes.icon} />,
  },
  [ActualOccupancyStatus.EVICTION]: {
    display: 'Eviction',
    containerClassName: classes.evictionContainer,
    Icon: (
      <EvictionIcon
        className={classNames(classes.icon, classes.evictionIcon)}
      />
    ),
  },
  [ActualOccupancyStatus.EXPIRED]: {
    display: 'Lease expired',
    Icon: <LeaseExpiredIcon className={classes.icon} />,
  },
  [ActualOccupancyStatus.UNDER_RENOVATION]: {
    display: 'Renovation',
    Icon: <RenovationIcon className={classes.icon} />,
  },
};

const OccupancyStatusWeight = [
  ActualOccupancyStatus.LEASED,
  ActualOccupancyStatus.EVICTION,
  ActualOccupancyStatus.UNDER_RENOVATION,
  ActualOccupancyStatus.FOR_RENT,
];

export const getPropertyStatus = (
  property: PropertyType
): ActualOccupancyStatus => {
  const countStatuses = countBy(
    property.units,
    (u) => OccupancyToActualStatus[u.occupancyStatus]
  );
  const keys = Object.keys(countStatuses) as Array<
    keyof typeof ActualOccupancyStatus
  >;

  for (const weight of OccupancyStatusWeight) {
    if (countStatuses[weight]) return weight;
  }

  return keys[0] as unknown as ActualOccupancyStatus;
};

import Typography, {
  TypographyProps,
} from 'components/generics/texts/Typography';
import { FC, ReactElement } from 'react';
import Skeleton from 'react-loading-skeleton';
import { toPercentageDisplay } from 'utils/number.extensions';

interface Props extends Omit<TypographyProps, 'prefix'> {
  value: number;
  prefix?: string | FC | ReactElement;
  suffix?: string | FC | ReactElement;
}

const Percentage = ({ value, prefix, suffix, ...props }: Props) => {
  return (
    <Typography {...props}>
      {value !== undefined ? (
        <>
          {prefix}
          {`${toPercentageDisplay(value ? value : 0)}%`}
          {suffix}
        </>
      ) : (
        <Skeleton
          height={14}
          width={50}
        />
      )}
    </Typography>
  );
};

export default Percentage;

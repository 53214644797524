import classNames from 'classnames';
import SpanTypography from 'components/generics/texts/SpanTypography';
import classes from 'components/properties/attributes/PropertyOccupancyStatus/index.module.scss';
import {
  getPropertyStatus,
  OccupancyStatusMap,
} from 'components/properties/attributes/PropertyOccupancyStatus/utils';
import { HTMLAttributes } from 'react';
import { PropertyType } from 'types/property-types';
import { stringOrFunction } from 'utils/helpers';

interface Props extends Omit<HTMLAttributes<HTMLDivElement>, 'property'> {
  property: PropertyType;
  withIcon?: boolean;
}

const PropertyOccupancyStatusLabel = ({
  property,
  withIcon = true,
  ...props
}: Props) => {
  const unitsCount = property?.unitsCount;
  const vacantUnitsCount = property?.vacantUnitsCount;

  const status = getPropertyStatus(property);
  const OccupancyItem = OccupancyStatusMap[status];

  return (
    <SpanTypography
      className={classNames(classes.text, OccupancyItem.containerClassName)}
      {...props}
    >
      <>
        {withIcon && OccupancyItem?.Icon}
        {stringOrFunction(OccupancyItem?.display, {
          unitsCount,
          vacantUnitsCount,
        })}
      </>
    </SpanTypography>
  );
};

export default PropertyOccupancyStatusLabel;

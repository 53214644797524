import { useEffect } from 'react';
import { useStocksAxios } from 'hooks/api/axios/stocks';
import useStockPriceStore from 'stores/StockPrice/stocksPrice';
import { manipulateStockPriceHistoryData } from 'hooks/requests/useStockPriceHistory/utils';
import { StockPriceItem } from '../../../components/generics/charts/StockPriceChart/types';

const useStockPriceHistory = (
  ticker: string,
  units: string,
  amount: number,
  interval: number,
  startDate?: Date
) => {
  const { stocksHistory, setHistory } = useStockPriceStore((state) => state);
  const [request, { data }] = useStocksAxios(`/${ticker}/${units}/${amount}`);
  const historyData = stocksHistory?.[ticker]?.[units]?.[amount] || [];

  useEffect(() => {
    if (historyData.length > 0) return;
    request();
  }, [ticker, units, amount]);

  useEffect(() => {
    if (!data?.history) return;
    setHistory(ticker, units, amount, data?.history);
  }, [data?.history]);

  return manipulateStockPriceHistoryData(
    historyData,
    interval,
    startDate
  ) as StockPriceItem[];
};

export default useStockPriceHistory;

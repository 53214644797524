import { useMutation } from '@apollo/client';
import { REAL_ESTATE_API } from 'graphql/config';
import { MutationHookOptions } from '@apollo/client/react/types/types';
import { DocumentNode } from 'graphql/language';
import useApolloClient from 'hooks/api/graphql/clients/useApolloClient';
import useLandaLazyQuery, {
  OptionsType,
} from 'hooks/api/graphql/functions/useLandaLazyQuery';
import useLandaPagination, {
  PaginationData,
  PaginationResult,
} from 'hooks/api/graphql/functions/useLandaPagination';
import useLandaQuery from 'hooks/api/graphql/functions/useLandaQuery';

export const useRealEstateQuery = (
  query: DocumentNode,
  options?: OptionsType
) => {
  const apolloClient = useApolloClient(REAL_ESTATE_API);
  return useLandaQuery(query, { ...(options || {}), client: apolloClient });
};

export const useRealEstateMutation = (
  mutation: DocumentNode,
  options?: MutationHookOptions
) => {
  const apolloClient = useApolloClient(REAL_ESTATE_API);
  return useMutation(mutation, { ...(options || {}), client: apolloClient });
};

export const useRealEstateLazyQuery = (
  query: DocumentNode,
  options?: OptionsType
) => {
  const apolloClient = useApolloClient(REAL_ESTATE_API);
  return useLandaLazyQuery(query, { ...(options || {}), client: apolloClient });
};

export const useRealEstatePagination = <TData>(
  query: DocumentNode,
  dataManipulator: (data: PaginationResult<TData>) => PaginationData<TData>,
  options?: OptionsType,
  limit = 20
) => {
  const apolloClient = useApolloClient(REAL_ESTATE_API);
  return useLandaPagination(
    query,
    {
      ...(options || {}),
      client: apolloClient,
    },
    dataManipulator,
    limit
  );
};

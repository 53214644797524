import classNames from 'classnames';
import ProgressBar from 'components/generics/progress/ProgressBar';
import classes from 'components/properties/tradeInformation/PrimaryInformation/index.module.scss';
import Typography from 'components/generics/texts/Typography';
import SparksIcon from 'public/assets/icons/sparks.svg';
import { HTMLAttributes } from 'react';
import { toPercentageDisplay } from 'utils/number.extensions';

interface Props extends HTMLAttributes<HTMLDivElement> {
  initialSharesQuantity: number;
  remainingShares: number;
  withProgressBar?: boolean;
}

const TOO_SMALL_THRESHOLD = 1;

const PrimaryInformation = ({
  initialSharesQuantity,
  remainingShares,
  withProgressBar = true,
  className,
}: Props) => {
  const sharesSold = initialSharesQuantity - remainingShares;
  const percentage = sharesSold / initialSharesQuantity;
  const readablePercentage = percentage * 100;
  const tooSmall = readablePercentage < TOO_SMALL_THRESHOLD;
  const title = tooSmall
    ? 'New'
    : `${toPercentageDisplay(readablePercentage)}% Sold`;

  return (
    <div className={classNames(classes.container, className)}>
      <div className={classes.topSectionContainer}>
        <SparksIcon className={classes.starIcon} />
        <Typography className={classes.percentageLabel}>{title}</Typography>
      </div>
      {withProgressBar && !tooSmall && (
        <div className={classes.progressBar}>
          <ProgressBar
            percentage={percentage}
            className={classes.progressBar}
          />
        </div>
      )}
    </div>
  );
};

export default PrimaryInformation;

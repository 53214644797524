import { findLast, groupBy, mean } from 'lodash';
import { HistoricalStockPrice } from 'stores/StockPrice/stocksPrice';

export const manipulateStockPriceHistoryData = (
  historyData: HistoricalStockPrice[],
  interval: number,
  startDate?: Date
) => {
  let newData = [...historyData] as HistoricalStockPrice[];
  newData = newData.sort((a, b) => a[1] - b[1]);
  newData = newData.map((item) => [item[0].floorToCents(), item[1]]);

  const result = [];
  const endDate = new Date().getTime() / 1000;
  let iterator = Math.max((startDate?.getTime() || 0) / 1000, newData[0]?.[1]);

  let latestValue = findLast(newData, (item) => item[1] <= iterator)?.[0];
  newData = newData.filter((item) => item[1] >= iterator && item[1] <= endDate);
  const groupedData = groupBy(newData, (item) =>
    Math.floor(item[1] / interval)
  );

  while (iterator < endDate) {
    const groupKey = Math.floor(iterator / interval);
    const values = groupedData[groupKey]?.map((item) => item[0]);
    const newValue = mean(values) || latestValue;
    result.push([newValue, iterator]);
    latestValue = newValue;
    iterator += interval;
  }

  return result;
};

import ComingSoonInformation from 'components/properties/tradeInformation/ComingSoonInformation';
import PreSecondaryInformation from 'components/properties/tradeInformation/PreSecondaryInformation';
import PrimaryInformation from 'components/properties/tradeInformation/PrimaryInformation';
import SecondaryInformation from 'components/properties/tradeInformation/SecondaryInformation';
import { PropertyStatuses } from 'consts/enums/property';
import { ElementType } from 'react';
import { PropertyType } from 'types/property-types';
import classes from './index.module.scss';

interface Props {
  item: PropertyType;
}

const TRADING_INFORMATION_MAP: { [key: string]: ElementType } = {
  [PropertyStatuses.PRIMARY]: PrimaryInformation,
  [PropertyStatuses.SECONDARY]: SecondaryInformation,
  [PropertyStatuses.PRE_SECONDARY]: PreSecondaryInformation,
};

const PropertyTradeInformation = ({
  item: { remainingShares, initialSharesQuantity, ticker, ownershipStatus },
}: Props) => {
  const DynamicComponent =
    TRADING_INFORMATION_MAP[ownershipStatus] || ComingSoonInformation;
  return (
    <>
      <DynamicComponent
        ticker={ticker}
        remainingShares={remainingShares}
        initialSharesQuantity={initialSharesQuantity}
        className={classes.dynamic}
      />
    </>
  );
};

export default PropertyTradeInformation;

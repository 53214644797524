import axios from 'axios';
import { useMapsAPI } from 'hooks/integrations/googleMaps/useMapsAPI';
import { useEffect, useState } from 'react';
import { PhotoType, PropertyAddress, PropertyType } from 'types/property-types';
import { getPropertyGoogleMapsLocation } from 'utils/property_utils';

type PropertyMainPhotoType = Partial<PropertyType> & {
  address: PropertyAddress;
  coordinates: {
    longitude: number;
    latitude: number;
  };
  photos: PhotoType[];
};

const usePropertyMainPhoto = (property: PropertyMainPhotoType) => {
  const [isOutsourced, setIsOutsourced] = useState(false);
  const [photo, setPhoto] = useState<string | undefined>(undefined);
  const { getStreetViewImage, getStaticMapUri } = useMapsAPI();
  const [loading, setLoading] = useState(false);

  const getFallbackPhoto = async () => {
    setLoading(true);
    const streetPhoto = getStreetViewImage(
      getPropertyGoogleMapsLocation(property.address)
    );
    try {
      await axios.get(streetPhoto);
      setPhoto(streetPhoto);
      setLoading(false);
      setIsOutsourced(true);
    } catch (e) {
      if (property.coordinates.longitude && property.coordinates.latitude) {
        setPhoto(
          getStaticMapUri(
            property.coordinates.longitude,
            property.coordinates.latitude
          )
        );
      }
      setLoading(false);
      setIsOutsourced(true);
    }
  };

  const getPhoto = async () => {
    if (property?.photos?.length > 0) return setPhoto(property?.photos[0].uri);
    await getFallbackPhoto();
  };

  useEffect(() => {
    getPhoto();
  }, [property.photos]);

  return { photo, loading, isOutsourced };
};

export default usePropertyMainPhoto;

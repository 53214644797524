import { useStocksAxios } from 'hooks/api/axios/stocks';
import { useEffect, useMemo } from 'react';
import useStockPriceStore from 'stores/StockPrice/stocksPrice';

const useStockPrice = (tickers: string[] | string, fetch = true) => {
  const { stocksPrices, setCurrentPrice } = useStockPriceStore();
  const arrayedTickers = useMemo(
    () => (Array.isArray(tickers) ? tickers : [tickers]),
    [tickers]
  );
  const [request, { data }] = useStocksAxios(`/${arrayedTickers.join(',')}`);

  useEffect(() => {
    if (!fetch) return;
    const hasAllPrices = arrayedTickers.every((key) =>
      Object.prototype.hasOwnProperty.call(stocksPrices, key)
    );
    if (hasAllPrices) return;
    request();
  }, [fetch, tickers]);

  useEffect(() => {
    if (!data || !arrayedTickers) return;
    const parsedData: { [key: string]: number } = {};
    if (arrayedTickers.length > 1) {
      Object.keys(data).forEach(
        (ticker) => (parsedData[ticker] = data?.[ticker]?.current)
      );
    } else {
      parsedData[arrayedTickers[0]] = data?.current;
    }
    setCurrentPrice(parsedData);
  }, [data, fetch, tickers]);

  const finalData: { [key: string]: number } = {};
  arrayedTickers.forEach((ticker) => {
    finalData[ticker] = stocksPrices[ticker];
  });
  return finalData;
};

export default useStockPrice;

import { FC, ReactNode } from 'react';
import InfiniteScroll, {
  Props as ScrollProps,
} from 'react-infinite-scroll-component';

interface Props extends ScrollProps {
  outerDivClassName: string;
  className?: string;
  next: () => void;
  hasMore: boolean;
  dataLength: number;
  key?: string;
  loader: ReactNode;
  children: ReactNode;
}

const InfiniteScrollContainer: FC<Props> = ({ children, ...props }) => {
  return <InfiniteScroll {...props}>{children}</InfiniteScroll>;
};

export default InfiniteScrollContainer;

import 'chart.js/auto';
import { StockPriceItem } from 'components/generics/charts/StockPriceChart/types';
import {
  getColorForValues,
  options,
} from 'components/generics/charts/StockPriceChart/utils';
import dateFormat from 'dateformat';
import { Line } from 'react-chartjs-2';
import { ChartProps } from 'react-chartjs-2/dist/types';

interface Props extends Omit<ChartProps, 'data' | 'options'> {
  data: StockPriceItem[];
  datasets?: any;
  options?: any;
}

const StockPriceChart = ({
  data,
  datasets,
  options: outerOptions,
  ...props
}: Props) => {
  const innerData = {
    labels: data.map((item: StockPriceItem) =>
      dateFormat(new Date(item[1] * 1000), 'dd mmmm yyyy')
    ),
    datasets: [
      {
        data: data.map((item: StockPriceItem) => item[0]),
        tension: 0.5,
        pointRadius: 0,
        pointHoverRadius: 0,
        borderWidth: 1,
        borderColor: getColorForValues(
          data.map((item: StockPriceItem) => item[0])
        ),
        ...datasets,
      },
    ],
  };

  return (
    <Line
      options={{ ...options, ...outerOptions }}
      data={innerData}
      {...props}
    />
  );
};

export default StockPriceChart;

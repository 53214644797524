import classNames from 'classnames';
import { HTMLAttributes } from 'react';
import classes from './index.module.scss';

interface Props extends HTMLAttributes<HTMLDivElement> {
  percentage: number;
  outerClassName?: string;
  innerClassName?: string;
}

const ProgressBar = ({ percentage, outerClassName, innerClassName }: Props) => {
  const actualPercentage = percentage * 100;

  return (
    <div className={classNames(classes.container, outerClassName)}>
      <div
        className={classNames(classes.progress, innerClassName)}
        style={{ width: `${actualPercentage}%` }}
      />
    </div>
  );
};

export default ProgressBar;

import classNames from 'classnames';
import Money from 'components/generics/texts/Money';
import Typography from 'components/generics/texts/Typography';
import PropertyOccupancyStatusLabel from 'components/properties/attributes/PropertyOccupancyStatus';
import { PROPERTY_TYPE_TO_TEXT } from 'components/properties/attributes/PropertyType/utils';
import PropertyTradeInformation from 'components/properties/PropertyCard/FeedPropertyCard/components/FeedPropertyBottomSection/components/PropertyTradeInformation';
import useStockPrice from 'hooks/requests/properties/useStockPrice';
import { HTMLAttributes } from 'react';
import { PropertyType } from 'types/property-types';
import classes from './index.module.scss';
import { getRent } from 'utils/property_utils';

interface Props extends HTMLAttributes<HTMLDivElement> {
  item: PropertyType;
  isResident?: boolean;
}

const FeedPropertyBottomSection = ({
  item,
  item: { ticker, address, propertyType },
  className,
  isResident,
}: Props) => {
  const propertyTypeText = PROPERTY_TYPE_TO_TEXT[propertyType] || propertyType;
  const { [ticker]: stockPrice } = useStockPrice(ticker, false);
  const monthlyRent = getRent(item);

  return (
    <div
      className={classNames(
        classes.container,
        className,
        isResident && classes.residentContainer
      )}
    >
      {!isResident ? (
        <>
          <div className={classes.leftSection}>
            <Typography className={classNames(classes.title, classes.ellipsis)}>
              {address.houseNumber} {address.street}
            </Typography>
            <Typography
              className={classNames(classes.subtitle, classes.ellipsis)}
            >
              <Typography className={classes.ellipsis}>
                {propertyTypeText} {' ∙ '}
                <PropertyOccupancyStatusLabel
                  className={classes.ellipsis}
                  withIcon={false}
                  property={item}
                />
              </Typography>
            </Typography>
          </div>
          <div className={classes.rightSection}>
            <Money
              withDecimal={true}
              className={classNames(classes.title, classes.stockPrice)}
              value={stockPrice}
            />
            <PropertyTradeInformation item={item} />
          </div>
        </>
      ) : (
        <>
          <div className={classes.leftSection}>
            <Money
              suffix={'/mo'}
              className={classNames(classes.title, classes.stockPrice)}
              value={monthlyRent}
            />
            <Typography
              className={classNames(
                classes.residentsSubtitle,
                classes.ellipsis
              )}
            >
              {address.houseNumber} {address.street}
            </Typography>
            <Typography className={classNames(classes.residentsSubtitle)}>
              {address.city.name}, {address.state.code}
            </Typography>
          </div>
          <div className={classes.residentRightSection}>
            <Typography
              className={classNames(classes.residentTitle, classes.ellipsis)}
            >
              {item.beds} bds {' ∙ '} {item.baths} ba
            </Typography>
            <Typography
              className={classNames(classes.subtitle, classes.ellipsis)}
            >
              {item.sqf} sqft
            </Typography>
          </div>
        </>
      )}
    </div>
  );
};

export default FeedPropertyBottomSection;

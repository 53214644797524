import produce from 'immer';
import { merge } from 'lodash';
import { createAndResetOnLogout } from 'stores/utils';

export type HistoricalStockPrice = [number, number];

interface StockPrice {
  stocksPrices: { [key: string]: number };
  stocksHistory: {
    [ticker: string]: {
      [units: string]: {
        [amount: number]: HistoricalStockPrice[];
      };
    };
  };
  setCurrentPrice: (data: { [key: string]: number }) => void;
  setHistory: (
    ticker: string,
    units: string,
    amount: number,
    history: HistoricalStockPrice[]
  ) => void;
}

const useStockPriceStore = createAndResetOnLogout<StockPrice>((set) => {
  return {
    stocksPrices: {},
    stocksHistory: {},
    setCurrentPrice: (data) => {
      set(
        produce((state: StockPrice) => {
          Object.keys(data).map((ticker) => {
            state.stocksPrices[ticker] = data[ticker];
          });
        })
      );
    },
    setHistory: (ticker, units, amount, history) => {
      set(
        produce((state: StockPrice) => {
          const newObj = {
            [units]: {
              [amount]: history,
            },
          };
          state.stocksHistory[ticker] = merge(
            state.stocksHistory[ticker] || {},
            newObj
          );
        })
      );
    },
  };
});

export default useStockPriceStore;

import classNames from 'classnames';
import StockPriceChart from 'components/generics/charts/StockPriceChart';
import Percentage from 'components/generics/texts/Percentage';
import useStockPriceHistory from 'hooks/requests/useStockPriceHistory/useStockPriceHistory';
import DownArrow from 'public/assets/icons/trend_down.svg';
import classes from './index.module.scss';

// Duration of 1 Day
const DURATION = 24 * 60 * 60;

const CHART_SIZE = {
  height: 18,
  width: 60,
};

interface Props {
  ticker: string;
}

const SecondaryInformation = ({ ticker }: Props) => {
  const stockHistory = useStockPriceHistory(ticker, 'months', 1, DURATION);
  const firstPrice = stockHistory?.[0]?.[0];
  const lastPrice = stockHistory?.at?.(-1)?.[0] || firstPrice;

  const isNegative = lastPrice < firstPrice;
  const percentage = (lastPrice / firstPrice - 1) * 100;

  const DirectionArrow = (
    <DownArrow
      className={classNames(classes.arrowIcon, isNegative && classes.negative)}
    />
  );

  return (
    <>
      <Percentage
        value={percentage}
        prefix={DirectionArrow}
        className={classNames(
          classes.percentageLabel,
          isNegative && classes.negative
        )}
      />
      <StockPriceChart
        data={stockHistory}
        {...CHART_SIZE}
        type="line"
      />
    </>
  );
};

export default SecondaryInformation;

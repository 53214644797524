import * as Sentry from '@sentry/nextjs';
import axios from 'axios';
import { ASSETS_URL, GOOGLE_MAPS_URL } from 'consts/api/urls';

const baseURL = GOOGLE_MAPS_URL;
const key = process.env.NEXT_PUBLIC_GEO_API_KEY;
const DEFAULT_ICON_URL = `icons/map_marker.png`;
const DEFAULT_WIDTH = 800;
const DEFAULT_HEIGHT = 300;

type GetStaticMapUriOptions = {
  width?: number;
  height?: number;
  iconURL?: string;
};

export const useMapsAPI = () => {
  const getPlaceLocation = async (place: string) => {
    try {
      const { data } = await axios.get(`/api/address_details`, {
        params: { place },
        baseURL: '/',
      });
      return data;
    } catch (err) {
      Sentry.captureException(err);
    }
  };

  const getStaticMapUri = (
    latitude?: number,
    longitude?: number,
    options?: GetStaticMapUriOptions
  ) => {
    const width = options?.width || DEFAULT_WIDTH;
    const height = options?.height || DEFAULT_HEIGHT;
    const icon = options?.iconURL || DEFAULT_ICON_URL;

    return axios.getUri({
      url: `staticmap`,
      params: {
        key,
        size: `${width}x${height}`,
        scale: 2,
        markers: `icon:${
          ASSETS_URL + icon
        }|shadow:false|${latitude},${longitude}`,
      },
      baseURL,
    });
  };

  const getLatLngFromAddress = async (address: string) => {
    try {
      const response = await axios.get(GOOGLE_MAPS_URL + 'geocode/json', {
        params: {
          address,
          key,
        },
      });
      if (response.data.status === 'OK' && response.data.results.length > 0) {
        const { lat, lng } = response.data.results[0].geometry.location;
        return { latitude: lat as number, longitude: lng as number };
      } else {
        return { latitude: 0, longitude: 0 };
      }
    } catch (err) {
      Sentry.captureException(err);
    }
    return { latitude: 0, longitude: 0 };
  };

  const getStreetViewImage = (location: string, size = '800x800', fov = 70) => {
    return axios.getUri({
      url: `streetview`,
      params: {
        key,
        size,
        fov,
        location,
        return_error_code: true,
      },
      baseURL,
    });
  };

  return {
    getLatLngFromAddress,
    getPlaceLocation,
    getStaticMapUri,
    getStreetViewImage,
  };
};
